import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from './components/NotFound';
import ScrollToTop from "./components/ScrollToTop";
import ChangePassword from "./pages/ChangePassword";
import SetPassword from "./pages/SetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import Finances from "./pages/Finances";
import AllFinances from "./pages/AllFinances";
import AllClientFinances from "./pages/AllClientFinances";
import Tasks from "./pages/Tasks";
import Settings from "./pages/Settings";
import Matters from "./pages/Matters";
import Contacts from "./pages/Contacts";
import SignIn from "./pages/SignIn";
import Profile from "./pages/Profile";
import Client from "./pages/Client";
import axios from "axios";
import GlobalState from "./contexts/GlobalState";
import Api from "./components/Api";
import ConfirmEmail from "./pages/ConfirmEmail";
import Dashboard from "./pages/Dashboard";
import ShareholdersTransactions from "./pages/ShareholdersTransactions";
import DateReport from "./pages/DateReport";
import DocumentsHistory from "./pages/DocumentsHistory";
import MattersDetails from "./pages/MattersDetails";
import AllLitigationsFinances from "./pages/AllLitigationsFinances";
import Events from "./pages/Events";
import Notifications from "./pages/Notifications";
import TaskDetails from "./pages/TaskDetails";
import AllTasksFinances from "./pages/AllTasksFinances";
import TaskHistory from "./pages/TaskHistory";
import Locations from "./pages/Locations";
import Search from "./pages/Search";
import OneSignal from 'react-onesignal';

//set english as default language if localstorage language is empty or does not exist
if (!(localStorage.getItem("BlpLanguage"))) localStorage.setItem("BlpLanguage", "en");

//setting attributes as per the lang in local storage
document.getElementsByTagName("html")[0].setAttribute("lang", localStorage.getItem("BlpLanguage") === "en" ? "en" : "ar");
document.getElementsByTagName("html")[0].setAttribute("dir", localStorage.getItem("BlpLanguage") === "ar" ? "rtl" : "ltr");
document.getElementById("bootstrap-link").setAttribute("href", localStorage.getItem("BlpLanguage") === "en" ? "/assets/css/bootstrap.min.css" : "/assets/css/bootstrap.rtl.min.css");

//setting axios default header
// axios.defaults.baseURL = 'http://localhost/blp/blp-backend/public/api/' + localStorage.getItem('BlpLanguage');
// axios.defaults.baseURL = 'https://blp.hellotree.dev/api/' + localStorage.getItem('BlpLanguage');
// axios.defaults.baseURL = 'https://dev-blp.hellotree.dev/api/' + localStorage.getItem('BlpLanguage');
axios.defaults.baseURL = ' https://app.bakhoslaw.com/api/' + localStorage.getItem('BlpLanguage');

export default function App() {
  const [generalData, setGeneralData] = useState();
  const [authUser, setAuthUser] = useState();
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [authUserApiOptions, setAuthUserApiOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [usersMenuOpen, setUsersMenuOpen] = useState(window.innerWidth > 991);
  const [dropElement, setDropElememt] = useState();
  const [draggedElement, setDraggedElemet] = useState();
  const [movePopup, setMovePopup] = useState(false);
  const [currentInput, setCurrentInput] = useState('');
  const [currentInputType, setCurrentInputType] = useState('');
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [currentInputValidations, setCurrentInputValidations] = useState('');
  const [profileUser, setProfileUser] = useState();
  const [profileClient, setProfileClient] = useState();
  const [changeInput, setChangeInput] = useState(0);
  const [updateMap, setUpdateMap] = useState(1);
  const [trigDelete, setTrigDelete] = useState(1);
  const [allCmsData, setAllCmsData] = useState({});
  const [addReminder, setAddReminder] = useState(false);
  const [currentReminder, setCurrentReminder] = useState('');
  const [currentReminderEditGl, setCurrentReminderEditGl] = useState('');
  const [addClient, setAddClient] = useState(false);
  const [currentClient, setCurrentClient] = useState({});
  const [apiSuccessData, setApiSuccessData] = useState({});
  const [currentEntityType, setCurrentEntityType] = useState('');
  const [openAddReminder, setopenAddReminder] = useState(false);
  const [entityContactPopup, setEntityContactPopup] = useState({});
  const [deleteEntityContactPopup, setDeleteEntityContactPopup] = useState({});
  const [clientRelationsSelect, setClientRelationsSelect] = useState('');
  const [shareHoldersPopupContent, setShareHolderPopupContent] = useState({});
  const [operations, setOperations] = useState([]);
  const [hearings, setHearings] = useState([]);
  const [litigations, setLitigations] = useState([]);
  const [activeMatter, setActiveMatter] = useState(generalData?.matterTypes[0]);
  const [activeMatterType, setActiveMatterType] = useState();
  const [allLitigations, setAllLitigations] = useState([]);
  const [activeFixedInputValue, setActiveFixedInputValue] = useState('');
  const [currentPopupComponent, setCurrentPopupComponent] = useState('');
  const [notifications, setNotifications] = useState({});
  const [reminders, setReminders] = useState({});
  const [unreadReminders, setUnreadReminders] = useState(0);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const [returnedFiles, setReturnedFiles] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [userNotificationToken, setUserNotificationToken] = useState('');
  const [updateDashboardDataGlobal, setUpdateDashboardDataGlobal] = useState('');

  useEffect(() => {
    if (!initialized) {
      OneSignal.init({
        appId: 'f4b5811f-86c1-47c9-ad33-0a28dcbf63b3',
        safari_web_id: 'web.dev.hellotree.dev-blp',
      }).then(() => {
        OneSignal.Slidedown.promptPush();
        setInitialized(true);

        if (OneSignal.User?.PushSubscription?.id) {
          setUserNotificationToken(OneSignal.User.PushSubscription.id);
        }
        OneSignal.User.PushSubscription.addEventListener("change", pushSubscriptionChangeListener);

        OneSignal.Notifications.addEventListener("foregroundWillDisplay", eventListener2);
      })
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  function eventListener2(event) {
    if (event?.notification?.additionalData?.type === 'reminder') {
      setUnreadReminders(unreadReminders + 1);
    } else {
      setUnreadNotifications(unreadNotifications + 1);
    }
  }

  function pushSubscriptionChangeListener(event) {
    if (event.current.token) {
      console.log(`The push subscription has received a token!`, event.current.token);
    }
  }

  useEffect(() => {
    if (authUser) {
      setUnreadReminders(authUser?.unread_reminders);
      setUnreadNotifications(authUser?.unread_notifications);
    }
  }, [authUser]);

  const [activeTask, setActiveTask] = useState({});


  //check if auth token is present to authenticate user and set Authuser
  useEffect(() => {
    if (localStorage.getItem('auth_token')) {
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');
      setAuthUserApiOptions({
        url: 'user-data',
        showBigLoader: 1,
        callback: res => {
          setAuthUser(res.data);
          setLoading(false);
        }
      });
    }
    else {
      setLoading(false);
    }
  }, []);


  //get general data
  useEffect(() => {
    setGeneralDataApiOptions({
      url: 'general',
      callback: res => {
        setGeneralData(res.data);
      }
    })
  }, []);

  useEffect(() => {
    if (authUser?.id) {
      if (OneSignal.User.PushSubscription.id) {
        axios.post('/register-user-token', { token: OneSignal.User.PushSubscription.id }).then((res) => {
        }).catch((err) => {
        });
      }
    }
  }, [authUser, userNotificationToken]);

  //check user access
  const chechkAccess = (auth, slug, type) => {
    if (auth?.id) {
      if (auth?.user_role?.slug === 'admin' || slug === 'dashboard') {
        return 1;
      } else {
        let acc = 0;
        if (auth?.user_role?.role_permissions?.length > 0) {
          auth?.user_role?.role_permissions.map(element => {
            if (element?.user_pages['slug'] === slug && element?.[type] === 1) {
              acc = 1;
            }
            return null;
          });
        }
        if (acc > 0) {
          return 1;
        }
        else {
          return 0;
        }
      }
    }

  };

  //private routes protection
  const PrivateRoute = (slug, elem, auth) => {
    if (!loading) {
      document.getElementsByTagName("html")[0].classList.remove('lock-scroll');
      if (auth?.id) {
        if (auth?.user_role?.slug === 'admin' || slug === 'dashboard') {
          return elem;
        } else {
          let acc = 0;
          if (auth?.user_role?.role_permissions?.length > 0) {
            auth?.user_role?.role_permissions.map(element => {
              if (element?.user_pages['slug'] === slug && element?.['browse'] === 1) {
                acc = 1;
              }
              return null;
            });
          }
          if (acc > 0) {
            return elem;
          }
          else {
            return <NotFound />;
          }
        }
      } else {
        return <SignIn />;
      }
      // return auth?.id ? elem : <SignIn />;
    }
  }


  useEffect(() => {
    if (authUser?.id) {
      axios.get('/get-user-reminders').then((res) => {
        setReminders(res?.data);
      })
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser?.id) {
      axios.get('/get-user-notifications').then((res) => {
        setNotifications(res?.data?.data);
      })
    }
  }, [authUser]);


  return (
    <>
      <Api options={generalDataApiOptions} />
      <Api options={authUserApiOptions} />
      {
        !generalData ?
          null
          :
          <GlobalState.Provider value={{
            activeFixedInputValueGlobal: [activeFixedInputValue, setActiveFixedInputValue],
            generalDataGlobal: [generalData, setGeneralData],
            authUserGlobal: [authUser, setAuthUser],
            chechkAccessGlobal: chechkAccess,
            logoutGlobal: [logout, setLogout],
            updateMapGlobal: [updateMap, setUpdateMap],
            draggedElementGlobal: [draggedElement, setDraggedElemet],
            dropElementGlobal: [dropElement, setDropElememt],
            currentInputGlobal: [currentInput, setCurrentInput],
            currentInputValueGlobal: [currentInputValue, setCurrentInputValue],
            currentInputValidationsGlobal: [currentInputValidations, setCurrentInputValidations],
            currentInputTypeGlobal: [currentInputType, setCurrentInputType],
            movePopupGlobal: [movePopup, setMovePopup],
            usersMenuOpenGlobal: [usersMenuOpen, setUsersMenuOpen],
            profileUserGlobal: [profileUser, setProfileUser],
            profileClientGlobal: [profileClient, setProfileClient],
            changeInputGlobal: [changeInput, setChangeInput],
            trigDeleteGlobal: [trigDelete, setTrigDelete],
            allCmsDataGlobal: [allCmsData, setAllCmsData],
            addReminderGlobal: [addReminder, setAddReminder],
            currentReminderGlobal: [currentReminder, setCurrentReminder],
            currentReminderEditGlobal: [currentReminderEditGl, setCurrentReminderEditGl],
            addClientGlobal: [addClient, setAddClient],
            currentClientGlobal: [currentClient, setCurrentClient],
            apiSuccessDataGlobal: [apiSuccessData, setApiSuccessData],
            openAddReminderGlobal: [openAddReminder, setopenAddReminder],
            currentEntityTypeGlobal: [currentEntityType, setCurrentEntityType],
            shareholdersContactPopupGlobal: [shareHoldersPopupContent, setShareHolderPopupContent],
            entityContactPopupGlobal: [entityContactPopup, setEntityContactPopup],
            deleteContactPopupGlobal: [deleteEntityContactPopup, setDeleteEntityContactPopup],
            clientRelationsSelectGlobal: [clientRelationsSelect, setClientRelationsSelect],
            operations, setOperations,
            hearings, setHearings,
            litigations, setLitigations,
            activeMatter, setActiveMatter,
            activeMatterType, setActiveMatterType,
            allLitigations, setAllLitigations,
            returnedFiles, setReturnedFiles,
            activeTask, setActiveTask,
            updateDashboardDataGlobal, setUpdateDashboardDataGlobal,
            currentPopupComponent, setCurrentPopupComponent,
            unreadRemindersGlobal: [unreadReminders, setUnreadReminders],
            unreadNotificationsGlobal: [unreadNotifications, setUnreadNotifications],
            notificationsGlobal: [notifications, setNotifications],
            remindersGlobal: [reminders, setReminders],
          }}>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route exact path="/" element={PrivateRoute('dashboard', <Dashboard />, authUser)} />
                <Route exact path="/contacts" element={PrivateRoute('contacts', <Contacts />, authUser)} />
                <Route axact path="/settings/:slug" element={PrivateRoute('settings', <Settings />, authUser)} />
                <Route axact path="/settings/:slug/:category" element={PrivateRoute('settings', <Settings />, authUser)} />
                <Route path="/settings" element={PrivateRoute('settings', <Settings />, authUser)} />
                <Route exact path="/finances/:slug" element={PrivateRoute('finances', <Finances />, authUser)} />
                <Route exact path="/finances/:slug/:all" element={PrivateRoute('finances', <AllFinances />, authUser)} />

                <Route exact path="/tasks" element={PrivateRoute('tasks', <Tasks />, authUser)} />
                <Route exact path="/tasks/:task_id" element={PrivateRoute('tasks', <TaskDetails />, authUser)} />
                <Route exact path="/tasks/:task_id/:slug" element={PrivateRoute('tasks', <TaskDetails />, authUser)} />
                <Route axact path="/tasks/:task_id/:slug/:folder_id" element={PrivateRoute('tasks', <TaskDetails />, authUser)} />
                <Route exact path="/tasks/:task_id/costs/:slug/all" element={PrivateRoute('tasks', <AllTasksFinances />, authUser)} />
                <Route exact path="/tasks/:task_id/history" element={PrivateRoute('history', <TaskHistory />, authUser)} />



                <Route exact path="/matters" element={PrivateRoute('matters', <Matters />, authUser)} />
                <Route exact path="/matters/:type" element={PrivateRoute('matters', <Matters />, authUser)} />
                <Route exact path="/matters/:type/:matter_id" element={PrivateRoute('matters', <MattersDetails />, authUser)} />
                <Route exact path="/matters/:type/:matter_id/:slug" element={PrivateRoute('matters', <MattersDetails />, authUser)} />
                <Route exact path="/matters/:type/:matter_id/costs/:slug/all" element={PrivateRoute('matters', <AllLitigationsFinances />, authUser)} />
                <Route exact path="/matters/:type/:matter_id/:slug/:folder_id" element={PrivateRoute('matters', <MattersDetails />, authUser)} />
                <Route exact path="/matters/:type/:matter_id/:slug/history" element={PrivateRoute('history', <DocumentsHistory />, authUser)} />

                <Route exact path="/events" element={PrivateRoute('events', <Events />, authUser)} />
                <Route exact path="/notifications" element={PrivateRoute('events', <Notifications />, authUser)} />
                <Route exact path="/locations" element={PrivateRoute('locations', <Locations />, authUser)} />

                <Route axact path="/profile/:user_id" element={PrivateRoute('profile', <Profile />, authUser)} />
                <Route axact path="/profile/:user_id/:slug" element={PrivateRoute('profile', <Profile />, authUser)} />
                <Route exact path="/profile/:user_id/:slug/history" element={PrivateRoute('history', <DocumentsHistory />, authUser)} />
                <Route axact path="/profile/:user_id/:slug/:folder_id" element={PrivateRoute('profile', <Profile />, authUser)} />

                <Route axact path="/client/:user_id" element={PrivateRoute('client', <Client />, authUser)} />
                <Route axact path="/client/:user_id/:slug" element={PrivateRoute('client', <Client />, authUser)} />
                <Route exact path="/client/:user_id/:slug/history" element={PrivateRoute('history', <DocumentsHistory />, authUser)} />
                <Route axact path="/client/:user_id/:slug/:folder_id" element={PrivateRoute('client', <Client />, authUser)} />
                <Route exact path="/client/:user_id/costs/:slug/all" element={PrivateRoute('finances', <AllClientFinances />, authUser)} />

                <Route exact path="/client/:user_id/shareholders/transactions" element={PrivateRoute('shareoperations', <ShareholdersTransactions />, authUser)} />
                <Route exact path="/client/:user_id/shareholders/date-report" element={PrivateRoute('date-report', <DateReport />, authUser)} />

                <Route exact path="/change-password/:email/:token" element={<ChangePassword />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/set-password/:email/:token" element={<SetPassword />} />
                <Route path="/confirm-email/:email" element={<ConfirmEmail />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route exact path="/search" element={<Search />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </GlobalState.Provider>
      }
    </>
  );
}